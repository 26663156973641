import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
// eslint-disable-next-line import/no-unresolved
import { createAsync, useLocation, useParams } from '@solidjs/router';
import { BreadcrumbItem, Breadcrumbs, Heading, Picture, Tag, Container, Prose } from '@troon/ui';
import { ErrorBoundary, Show, Suspense } from 'solid-js';
import { Meta, Title } from '@solidjs/meta';
import { getConfigValue } from '../../modules/config';
import { getDocument } from '../api/documents/[docType]/[slug]';
import { NotFoundContent } from '../../partials/404';

dayjs.extend(utc);
dayjs.extend(tz);

export default function PressReleasePage() {
	const loc = useLocation();
	const params = useParams<{ release: string }>();
	const data = createAsync(
		() => {
			return getDocument('press-release', params.release);
		},
		{
			deferStream: true,
		},
	);

	return (
		<ErrorBoundary fallback={<NotFoundContent />}>
			<Suspense>
				<Show when={data()}>
					{(data) => (
						<>
							<Title>{data().title} | Press Release | Troon</Title>
							<Meta name="description" content={data().title} />
							<Meta name="og:title" content={`${data().title} | Troon`} />
							<Meta name="og:url" content={`https://${getConfigValue('HOST')}/press-releases/${data()?.slug}`} />
							<Meta name="og:type" content="article" />
							<Meta name="article:published_time" content={dayjs(data()?.date).toISOString()} />
							<Meta
								name="og:image"
								content={`${getConfigValue('IMAGE_HOST')}/digital/press-releases/${data()?.image}`}
							/>
						</>
					)}
				</Show>
			</Suspense>
			<Container class="pb-16 pt-4">
				<Suspense>
					<Show when={data()}>
						<Breadcrumbs>
							<BreadcrumbItem href="/press-releases">Press Releases</BreadcrumbItem>
							<BreadcrumbItem href={loc.pathname}>{data()?.title}</BreadcrumbItem>
						</Breadcrumbs>
					</Show>
				</Suspense>
			</Container>
			<Container size="small" class="flex flex-col gap-8">
				<Tag class="text-sm">Press release</Tag>
				<Heading as="h1">
					<Suspense>
						<Show when={data()}>{(data) => <>{data().title}</>}</Show>
					</Suspense>
				</Heading>
				<time class="text-lg">
					<Suspense>
						<Show when={data()}>{(data) => dayjs(data().date, 'America/Phoenix').format('MMMM D, YYYY')}</Show>
					</Suspense>
				</time>
			</Container>
			<div class="container mx-auto my-8 max-w-screen-lg sm:px-6 md:px-12">
				<Picture
					src={`${getConfigValue('IMAGE_HOST')}/digital/press-releases/${data()?.image}`}
					sizes={[
						[480, 270],
						[1024, 576],
					]}
					class="w-full sm:rounded"
					alt=""
					crop="face"
				/>
			</div>
			<Suspense>
				<Show when={data()?.content}>
					{(content) => (
						<Container size="small">
							<Prose innerHTML={content()} />
						</Container>
					)}
				</Show>
			</Suspense>
		</ErrorBoundary>
	);
}
